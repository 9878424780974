import React from 'react';
import Drawer from '../../../../../../components/UI/Drawer/Drawer';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../../../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../../../../components/UI/Forms/FormInput';
import { format, parseJSON } from 'date-fns';
import { FormCheckbox } from '../../../../../../components/UI/Forms/FormCheckbox';
import localesConfig from '../../../../../../locales/locales.config';
import { FormRadio } from '../../../../../../components/UI/Forms/FormRadio';
import offerRevisionsService from '../../../../../../services/offers/offerRevisionsService';
import { toast } from 'react-toastify';
import { FormDisplay } from '../../../../../../components/UI/Displays/FormDisplay';
import EuroDisplay from '../../../../../../components/UI/Displays/EuroDisplay';
import { List } from '../../../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../../../components/UI/ItemList/ListItem';
import { FormActions } from '../../../../../../components/UI/Forms/FormActions';
import { useSessionStorageState } from 'ahooks';

const OfferRevisionPrintDrawer = ({ isOpen, setIsOpen, offer }) => {

  const { t } = useTranslation()

  const [initialValues, setInitialValues] = useSessionStorageState(`print_${offer.offer_revision.uuid}`, {
    defaultValue: {
      recipient: offer?.company?.business_name ?? "",
      referent: offer?.company_person?.full_name ?? "",
      date: format(parseJSON(new Date()), 'yyyy-MM-dd'),
      version: '',
      general: true,
      tools: false,
      sub_offers: [],
      print_total_prices: false,
      print_total_net_prices: false,
      print_item_prices: false,
      print_item_net_price: false,
      print_draw_include: false,
      locale: 'it'
    }
  });

  const validationSchema = Yup.object().shape({
    recipient: Yup.string().max(200).required(),
    referent: Yup.string().max(200).notRequired(),
    date: Yup.date().nullable().required(),
    version: Yup.string().max(50).notRequired(),
    general: Yup.boolean().nullable().notRequired(),
    tools: Yup.boolean().nullable().notRequired(),
    sub_offers: Yup.array().nullable().notRequired(),
    print_total_prices: Yup.boolean().nullable().notRequired(),
    print_total_net_prices: Yup.boolean().nullable().notRequired(),
    print_item_prices: Yup.boolean().nullable().notRequired(),
    print_item_net_price: Yup.boolean().nullable().notRequired(),
    print_draw_include: Yup.boolean().nullable().notRequired(),
    locale: Yup.string().required().oneOf(localesConfig.availableTranslations.map(at => at.locale))
  })

  const handleSubmit = (values, { setSubmitting }) => {
    setInitialValues(values)
    offerRevisionsService.print(
      offer.uuid,
      offer.offer_revision.uuid,
      offer.number + '.pdf',
      values
    ).catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setIsOpen(false)
    })
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={`${t('print')} ${offer.number} Rev. ${offer.offer_revision.revision}`}
    >
      <FormikProvider value={formik}>
        <Form>
          <List>
            <ListItem label='Intestazione'>
              <div className='row'>
                <div className='col-lg-6'>
                  <FormGroup label={t('recipient')} name="recipient" required>
                    <FormInput name="recipient"/>
                  </FormGroup>
                </div>
                <div className='col-lg-6'>
                  <FormGroup label={t('offers.date')} name="date" required>
                    <FormInput type="date" name="date"/>
                  </FormGroup>
                </div>
                <div className='col-lg-6'>
                  <FormGroup label={t('referent')} name="referent">
                    <FormInput name="referent"/>
                  </FormGroup>
                </div>
                <div className='col-lg-6'>
                  <FormGroup label={t('offers.version')} name="version">
                    <FormInput name="version"/>
                  </FormGroup>
                </div>
              </div>
            </ListItem>
            <ListItem>
              <h5>{t('offers.print_price')}</h5>
              <div className='row'>
                <div className='col-lg-4'>
                  <FormDisplay label={t('offers.total_price')}>
                    <h5><EuroDisplay value={offer.offer_revision?.total_price}/></h5>
                  </FormDisplay>
                </div>
                <div className='col-lg-4'>
                  <FormDisplay label={t('sub_offer_revisions.offer_rev_net_price_total')}>
                    <h5><EuroDisplay value={offer?.offer_revision?.total_net_price}/></h5>
                  </FormDisplay>
                </div>
                <div className='col-lg-4'>
                  <FormDisplay label={t('offers.print_price')}>
                    <h5><EuroDisplay value={offer.offer_revision.accepted_price}/></h5>
                  </FormDisplay>
                </div>
              </div>
            </ListItem>
            <ListItem label={t('sub_offers.plural')}>
              <div className='text-muted mb-2'>{t('offers.print_sub_offers_description')}</div>
              <FormCheckbox name='general' label={t('recap')}/>
              <FormCheckbox name='tools' label={t('sub_offers.tools')}/>
              {offer.offer_revision.sub_offers.map(
                so => <FormCheckbox
                  disabled={!so.is_enabled}
                  key={`check_print_${so.uuid}`}
                  value={so.uuid}
                  name='sub_offers'
                  label={so.code}
                />)}
            </ListItem>
            <ListItem label={t('options')}>
              <FormCheckbox
                name='print_total_prices'
                label={t('offers.print_total_prices')}
                description={t('offers.print_total_prices_description')}
              />
              <FormCheckbox
                name='print_total_net_prices'
                label={t('offers.print_total_net_prices')}
                description={t('offers.print_total_net_prices_description')}
              />
              <FormCheckbox
                name='print_item_prices'
                label={t('offers.print_item_prices')}
                description={t('offers.print_item_prices_description')}
              />
              <FormCheckbox
                name='print_item_net_price'
                label={t('offers.print_item_net_price')}
                description={t('offers.print_item_net_price_description')}
              />
              <FormCheckbox
                name='print_draw_include'
                label={t('offers.print_draw_include')}
              />
            </ListItem>
            <ListItem label={t('settings.translation_language')}>
              <div className='text-muted mb-2'>{t('settings.translation_language_description')}</div>
              {localesConfig.availableTranslations.map(translation =>
                <FormRadio
                  key={translation.locale}
                  id={`radio_${translation.locale}`}
                  name='locale'
                  value={translation.locale}
                  label={translation.label}
                />
              )}
            </ListItem>
          </List>

          <FormActions
            withReset={true}
            onCancel={() => setIsOpen(false)}
          />
        </Form>
      </FormikProvider>
    </Drawer>
  )

}

export default OfferRevisionPrintDrawer
