import { find, sumBy } from 'lodash';
import { arrayMoveImmutable } from 'array-move';
import PartTypeEnum from '../../../../../constants/enums/PartTypeEnum';
import { formatISO } from 'date-fns';

/**
 * Inizializzazione dei valori di default
 * @param state
 * @param payload
 * @returns {{offer, current_selected_tab: string, loading: boolean}}
 */
export const initOfferState = payload => ({
  offer: payload,
  revision_editable: false,
  current_selected_tab: "default",
  loading: false
})

/**
 *
 * @param state
 * @param payload
 * @returns {*&{offer: (*&{offer_revision: (*&{color_configs})})}}
 */
export const updateColorConfig = (state, payload) => ({
  ...state, offer: {
    ...state.offer,
    offer_revision: {
      ...state.offer.offer_revision,
      color_configs: payload
    }
  }
})

/**
 * Cambio tab
 * @param state
 * @param payload
 * @returns {*&{current_selected_tab}}
 */
export const changeOfferTab = (state, payload) => ({ ...state, current_selected_tab: payload })

/**
 * Cambia la revisione
 * @param state
 * @param payload
 * @returns {*&{offer: (*&{offer_revision})}}
 */
export const changeOfferRevision = (state, payload) => ({
  ...state, offer: {
    ...state.offer,
    offer_revision: payload
  }
})

/**
 * Aggiorna la revisione
 * @param state
 * @param payload
 * @returns {*&{offer: (*&{offer_revision})}}
 */
export const updateRevision = (state, payload) => {
  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: {
        ...state.offer.offer_revision,
        ...payload
      }
    }
  }

}

/**
 * @param state
 * @param payload
 * @returns {*}
 */
export const updateMassivePrice = (state, payload) => {
  let { percentage } = payload
  let revision = state.offer.offer_revision;
  let subOffers = revision.sub_offers;

  subOffers = subOffers.map(so => {
      let subOfferItems = so.sub_offer_items
      .map(soi => soi.part_type === PartTypeEnum.values.WITH_MEASURE || soi.part_type === PartTypeEnum.values.WITHOUT_MEASURE || soi.part_type === PartTypeEnum.values.CUSTOM
        ? updateSubOfferItemTotalByPrice(soi, revision, percentage)
        : soi
      )

      return updateSubOfferTotalBySubOfferItem({
        ...so,
        is_invalid: subOfferItems.filter(soi => soi.is_price_invalid || soi.is_cost_invalid).length > 0,
        sub_offer_items: subOfferItems
      })
    }
  );

  revision.percentage_modifier = percentage / 100;
  revision.percentage_modified_at = formatISO(new Date())

  revision = updateOfferRevisionTotals(
    updateRevisionSubOffers(revision, subOffers)
  )

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }
}

/**
 * @param state
 * @param subOfferUuid
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const toggleSubOffer = (state, { subOfferUuid }) => {
  let revision = state.offer.offer_revision;
  revision = updateRevisionSubOffers(revision, revision.sub_offers.map(so => so.uuid === subOfferUuid ? { ...so, is_enabled: !so.is_enabled } : so));
  revision = updateOfferRevisionTotals(revision)

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: revision
    }
  }
}

/**
 * @param state
 * @param payload
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const addSubOffer = (state, payload) => {
  let revision = state.offer.offer_revision;
  revision = updateRevisionSubOffers(revision, [...revision.sub_offers, payload]);
  revision = updateOfferRevisionTotals(revision)

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }
}

/**
 * @param state
 * @param payload
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const removeSubOffer = (state, payload) => {
  let revision = state.offer.offer_revision;
  revision = updateRevisionSubOffers(revision, revision.sub_offers.filter(so => so.uuid !== payload.uuid));
  revision = updateOfferRevisionTotals(revision)

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }
}

/**
 *
 * @param state
 * @param payload
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const updateRevisionCategoriesMargin = (state, payload) => {
  let revision = state.offer.offer_revision;
  let subOffers = revision.sub_offers;
  revision = updateRevisionPartCategoryMarginByUuid(revision, payload);

  // Ricalcolo i valori di ogni singolo item che appartiene a quella part_category
  subOffers = subOffers.map(so => updateSubOfferTotalBySubOfferItem({
      ...so,
      sub_offer_items: so.sub_offer_items
      .map(soi => soi.part_margin
        ? soi
        : soi.part_category?.id === payload.id ? updateSubOfferItemTotalByRevisionMargin(soi, revision, payload) : soi)
    })
  );

  revision = updateOfferRevisionTotals(updateRevisionSubOffers(revision, subOffers))

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: revision
    }
  }
}

/**
 * Aggiorna la marginalità di una categoria e ritorna la revision aggiornata
 * @param revision
 * @param partCategory
 * @returns {*&{part_categories: *}}
 */
export const updateRevisionPartCategoryMarginByUuid = (revision, partCategory) => {
  return {
    ...revision,
    part_categories: revision.part_categories.map(pc => pc.id === partCategory.id ? partCategory : pc)
  }
}

/**
 *
 * @param state
 * @param subOffer
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const updateSubOffer = (state, { subOffer }) => {
  let revision = state.offer.offer_revision;
  let subOffers = updateSubOffers(revision.sub_offers, subOffer)
  revision = updateOfferRevisionTotals(updateRevisionSubOffers(revision, subOffers))

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }
}

/**
 *
 * @param state
 * @param subOffer
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const updateSubOfferTools = (state, { subOfferTools }) => {
  let revision = state.offer.offer_revision;
  revision.sub_offer_tools = updateSubOfferTotalBySubOfferItem(subOfferTools)
  revision = updateOfferRevisionTotalsByTools(revision, subOfferTools)

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }
}

/**
 *
 * @param state
 * @param payload
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const updateSubOfferToolsFromFile = (state, payload) => {

  let revision = state.offer.offer_revision;
  revision.sub_offer_tools = payload;
  revision = updateOfferRevisionTotalsByTools(revision, payload)

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }
}


/**
 * Aggiorna la configurazione colori delle sotto offerte
 * @param state
 * @param subOffer
 * @param colorConfigs
 * @returns {*&{offer: (*&{offer_revision: (*&{sub_offers: (*|{color_config}|T)[]})})}}
 */
export const updateSubOfferColorConfig = (state, { subOffer, colorConfigs }) => {

  let revision = state.offer.offer_revision;

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: {
        ...revision,
        sub_offers: revision.sub_offers.map(so => subOffer.uuid === so.uuid ? { ...so, color_config: colorConfigs } : so)
      }
    }
  }
}

/**
 * Aggiunge un item ad una subOffer
 * @param state
 * @param subOfferUuid
 * @param subOfferItem
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const addSubOfferItem = (state, { subOfferUuid, subOfferItem }) => {
  let revision = state.offer.offer_revision;
  let subOffers = revision.sub_offers;
  let subOffer = findSubOfferByUuid(subOffers, subOfferUuid);

  subOffer = updateSubOfferTotalBySubOfferItem({
    ...subOffer,
    is_invalid: subOffer.is_invalid || subOfferItem.is_price_invalid || subOfferItem.is_cost_invalid,
    sub_offer_items: [...subOffer.sub_offer_items, subOfferItem]
  })

  subOffers = updateSubOffers(subOffers, subOffer)
  revision = updateOfferRevisionTotals(updateRevisionSubOffers(revision, subOffers))

  return {
    ...state,
    offer: { ...state.offer, offer_revision: isRevisionInvalid(revision) }
  }
}

/**
 * Aggiorno un singolo item
 * @param state
 * @param subOfferUuid
 * @param subOfferItem
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const updateSubOfferItem = (state, { subOfferUuid, subOfferItem }) => {
  let revision = state.offer.offer_revision;
  let subOffers = revision.sub_offers;
  let subOffer = findSubOfferByUuid(subOffers, subOfferUuid)

  subOffer = updateSubOfferSubOfferItems(subOffer, subOfferItem)

  subOffers = updateSubOffers(subOffers, updateSubOfferTotalBySubOfferItem(subOffer))
  revision = updateOfferRevisionTotals(updateRevisionSubOffers(revision, subOffers))

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }
}

/**
 * Aggiorna la posizione di un item all'interno di una sottofferta
 * @param state
 * @param subOfferUuid
 * @param oldPosition
 * @param newPosition
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const swapSubOfferItemPositions = (state, { subOfferUuid, oldPosition, newPosition }) => {
  let revision = state.offer.offer_revision;
  let subOffers = revision.sub_offers;
  let subOffer = findSubOfferByUuid(subOffers, subOfferUuid)

  // Aggiorno le sotto offerte swappando la posizione dell'item nella sottooffert
  subOffers = updateSubOffers(subOffers, {
    ...subOffer, sub_offer_items: arrayMoveImmutable(subOffer.sub_offer_items,
      subOffer.sub_offer_items.findIndex(soi => soi.id === oldPosition),
      subOffer.sub_offer_items.findIndex(soi => soi.id === newPosition)
    )
  })
  revision = updateOfferRevisionTotals(updateRevisionSubOffers(revision, subOffers))

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: revision
    }
  }
}

/**
 * Rimuove un elemento da una sub offer per indice
 * @param state
 * @param subOfferUuid
 * @param index
 * @returns {*&{offer: (*&{offer_revision: *})}}
 */
export const removeSubOfferItemByIndex = (state, { subOfferUuid, index }) => {

  let revision = state.offer.offer_revision;
  let subOffers = revision.sub_offers;
  let subOffer = findSubOfferByUuid(subOffers, subOfferUuid);

  subOffer.sub_offer_items.splice(index, 1)

  subOffer = updateSubOfferTotalBySubOfferItem({
    ...subOffer,
    is_invalid: subOffer.sub_offer_items.filter(subOfferItem => subOfferItem.is_price_invalid || subOfferItem.is_cost_invalid).length > 0
  })
  subOffers = updateSubOffers(subOffers, subOffer)
  revision = updateOfferRevisionTotals(updateRevisionSubOffers(revision, subOffers))

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }

}

/**
 * Aggiunge un item di tipo tool
 * @param state
 * @param subOfferUuid
 * @param subOfferItem
 * @returns {*}
 */
export const addSubOfferToolItem = (state, { subOfferItem }) => {
  let revision = state.offer.offer_revision;
  let subOfferTools = revision.sub_offer_tools;


  revision.sub_offer_tools = updateSubOfferTotalBySubOfferItem({
    ...subOfferTools,
    is_invalid: revision.sub_offer_tools.is_invalid || subOfferItem.is_price_invalid,
    sub_offer_items: [...subOfferTools.sub_offer_items, subOfferItem]
  })
  revision = updateOfferRevisionTotalsByTools(revision, subOfferTools)

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }
}

/**
 * Aggiorna un item di tipo tool
 * @param state
 * @param subOfferUuid
 * @param subOfferItem
 * @returns {*}
 */
export const updateSubOfferToolItem = (state, { subOfferItem }) => {
  let revision = state.offer.offer_revision;
  let subOfferTools = revision.sub_offer_tools;
  let subOfferItems = subOfferTools.sub_offer_items.map(soi => soi.id === subOfferItem.id ? subOfferItem : soi)

  revision.sub_offer_tools = updateSubOfferTotalBySubOfferItem({
    ...subOfferTools,
    is_invalid: subOfferItems.filter(subOfferItem => subOfferItem.is_price_invalid).length > 0,
    sub_offer_items: subOfferItems
  })

  revision = updateOfferRevisionTotalsByTools(revision, subOfferTools)

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }
}

/**
 * Rimuovi un item di tipo tool
 * @param state
 * @param subOfferUuid
 * @param subOfferItem
 * @returns {*}
 */
export const removeSubOfferToolItem = (state, { subOfferUuid, index }) => {
  let revision = state.offer.offer_revision;
  let subOfferTools = revision.sub_offer_tools;

  subOfferTools.sub_offer_items.splice(index, 1)

  revision.sub_offer_tools = updateSubOfferTotalBySubOfferItem({
    ...subOfferTools,
    is_invalid: subOfferTools.sub_offer_items.filter(subOfferItem => subOfferItem.is_price_invalid).length > 0
  })
  revision = updateOfferRevisionTotalsByTools(revision, subOfferTools)

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_revision: isRevisionInvalid(revision)
    }
  }
}

/**
 * Aggiorna i totali della revisione
 * @param revision
 * @returns {*&{total_net_price: (number|number), total_net_cost: number, margin: (number|number), total_price: number, total_cost: number, margin_rate: (number|number)}}
 */
const updateOfferRevisionTotals = revision => {

  let enabledSubOffers = revision.sub_offers.filter(so => so.is_enabled);

  let total_price = sumBy(enabledSubOffers, 'total_price')
  let total_net_price = sumBy(enabledSubOffers, 'total_net_price')
  let total_cost = sumBy(enabledSubOffers, 'total_cost')
  let total_net_cost = sumBy(enabledSubOffers, 'total_net_cost')
  let margin = total_net_cost ? 1 - (total_cost / total_net_cost) : 0
  let margin_rate = total_net_price ? 1 - (total_cost / total_net_price) : 0
  let project_cost = revision.hour_amount * revision.hour_cost
  let full_cost = total_cost + project_cost
  let full_margin = total_net_cost ? 1 - (full_cost / total_net_cost) : 0
  let project_net_cost = project_cost / (1 - revision.project_margin)
  let total_tools_price = sumBy(revision.sub_offer_tools.sub_offer_items, 'total_price')
  let total_price_with_tools = total_tools_price + total_price;
  let total_net_price_with_tools = total_net_price + revision.sub_offer_tools.total_net_price;

  return {
    ...revision,
    total_price: total_price,
    total_net_price: total_net_price,
    total_cost: total_cost,
    total_net_cost: total_net_cost,
    margin: margin,
    margin_rate: margin_rate,
    project_cost: project_cost,
    full_cost: full_cost,
    full_margin: full_margin,
    project_net_cost: project_net_cost,
    total_tools_price: total_tools_price,
    total_price_with_tools: total_price_with_tools,
    total_net_price_with_tools: total_net_price_with_tools
  }
}

/**
 * Aggiorna i totali della revisione quando cambia un accessorio
 * @param revision
 */
const updateOfferRevisionTotalsByTools = revision => {
  let enabledSubOffers = revision.sub_offers.filter(so => so.is_enabled);

  let total_price = sumBy(enabledSubOffers, 'total_price')
  let total_tools_price = sumBy(revision.sub_offer_tools.sub_offer_items, 'total_price')
  let total_price_with_tools = total_tools_price + total_price;
  let total_net_price_with_tools = revision.total_net_price + revision.sub_offer_tools.total_net_price;

  return {
    ...revision,
    total_tools_price: total_tools_price,
    total_price_with_tools: total_price_with_tools,
    total_net_price_with_tools: total_net_price_with_tools
  }
}

/**
 * Aggiorno la lista delle sotto offerte nella revisione
 * @param revision
 * @param subOffers
 * @returns {*&{sub_offers: *[]}}
 */
const updateRevisionSubOffers = (revision, subOffers) => {
  return {
    ...revision,
    sub_offers: [...subOffers]
  }
}


/**
 * Aggiorna la sotto offerta all'interno della lista e la ritorna
 * @param subOffers
 * @param subOffer
 * @returns {*&{offer: (*&{offer_revision: (*&{sub_offers: *})})}}
 */
const updateSubOffers = (subOffers, subOffer) => {
  return subOffers.map(so => so.uuid === subOffer.uuid ? subOffer : so);
}

/**
 * Trova la sotto commessa e la ritorna
 * @returns {unknown}
 * @param subOffers
 * @param subOfferUuid
 */
const findSubOfferByUuid = (subOffers, subOfferUuid) => {
  return find(subOffers, so => so.uuid === subOfferUuid)
}

/**
 * Aggiorna la lista degli articoli e ritorna la sotto offerta aggiornata
 * @param subOffer
 * @param subOfferItem
 * @returns {*}
 */
const updateSubOfferSubOfferItems = (subOffer, subOfferItem) => {

  const subOfferItems = subOffer.sub_offer_items.map(soi => soi.uuid === subOfferItem.uuid ? subOfferItem : soi);

  return {
    ...subOffer,
    is_invalid: subOfferItems.filter(subOfferItem => subOfferItem.is_price_invalid || subOfferItem.is_cost_invalid).length > 0,
    sub_offer_items: subOfferItems
  };
}

/**
 * Aggiorna i totali di una sotto offerta al cambiamento di un articolo e la ritorna aggiornata
 * @param subOffer
 * @returns {*&{total_net_price: number, total_net_cost: number, cost: number, total_price: number, total_cost: number, price: number, net_price: number, net_cost: number}}
 */
const updateSubOfferTotalBySubOfferItem = (subOffer) => {

  let price = sumBy(subOffer.sub_offer_items, 'total_price')
  let total_price = price * subOffer.qty
  let net_price = subOffer.type
    ? price - (price * subOffer.discount)
    : sumBy(subOffer.sub_offer_items, 'total_net_price')

  let total_net_price = net_price * subOffer.qty
  let cost = sumBy(subOffer.sub_offer_items, 'total_cost')
  let total_cost = cost * subOffer.qty;
  let net_cost = sumBy(subOffer.sub_offer_items, 'total_net_cost')
  let total_net_cost = net_cost * subOffer.qty
  let margin = total_net_cost ? 1 - (total_cost / total_net_cost) : 0
  let net_margin = price ? 1 - (cost / price) : 0
  let min_margin = net_cost ? 1 - (cost / net_cost) : 0

  return {
    ...subOffer,
    price: Number(price),
    net_price: Number(net_price),
    total_price: Number(total_price),
    total_net_price: Number(total_net_price),
    cost: Number(cost),
    net_cost: Number(net_cost),
    total_cost: Number(total_cost),
    total_net_cost: Number(total_net_cost),
    margin: Number(margin),
    net_margin: Number(net_margin),
    min_margin: Number(min_margin)
  }
}

/**
 * Aggiorna i campi legati alla marginalità della revisione ritorna l'elemento aggiornato
 * @param subOfferItem
 * @param revision
 * @param partCategoryMargin
 * @returns {*&{total_cost: number, net_cost: number}}
 */
const updateSubOfferItemTotalByRevisionMargin = (subOfferItem, revision, partCategoryMargin) => {

  let total_price = subOfferItem.qty * subOfferItem.price
  let total_net_price = total_price * (1 - revision.discount)
  let total_cost = subOfferItem.qty * subOfferItem.cost
  let total_net_cost = total_cost / (1 - partCategoryMargin?.margin ?? 0)
  let calculated_price = subOfferItem.cost ? (subOfferItem.cost / (1 - partCategoryMargin?.margin ?? 0)) / 0.425 : 0

  return {
    ...subOfferItem,
    total_price: Number(total_price),
    total_net_price: Number(total_net_price),
    total_cost: Number(total_cost),
    total_net_cost: Number(total_net_cost),
    calculated_price: Number(calculated_price)
  }
}

/**
 * @param subOfferItem
 * @param revision
 * @param percentage
 * @returns {*&{total_net_price: number, total_price: number, price: number}}
 */
const updateSubOfferItemTotalByPrice = (subOfferItem, revision, percentage) => {

  let price = subOfferItem.part_price + ((subOfferItem.part_price / 100) * percentage);
  let total_price = subOfferItem.qty * price
  let total_net_price = total_price * (1 - revision.discount)
  let net_margin = price ? 1 - (subOfferItem.cost / price) : 0;

  return {
    ...subOfferItem,
    price: Number(price),
    total_price: Number(total_price),
    total_net_price: Number(total_net_price),
    net_margin: Number(net_margin)
  }
}

/**
 * @param revision
 * @returns {*&{is_invalid: (boolean|boolean|*)}}
 */
const isRevisionInvalid = revision => {
  return {
    ...revision,
    is_invalid: revision.sub_offers.filter(subOffer => subOffer.is_invalid).length > 0 || revision.sub_offer_tools.is_invalid
  }
}
