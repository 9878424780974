import * as Yup from 'yup';
import PartTypeEnum from '../../../constants/enums/PartTypeEnum';

const initialValues = {
  part_type: '',
  code: '',
  words: [],
};

const setInitialValues = (data) => ({
  part_type: data?.part_type?.value,
  code: data?.code,
  words: data?.words,
});

const storeValidation = Yup.object().shape({
  part_type: Yup.string()
  .required()
  .oneOf([PartTypeEnum.values.WITHOUT_MEASURE, PartTypeEnum.values.WITH_MEASURE, PartTypeEnum.values.ASSEMBLY]),
  code: Yup.string().when('part_type', {
    is: PartTypeEnum.values.ASSEMBLY,
    then: Yup.string().length(2),
    otherwise: Yup.string().length(3),
  }).required(),
  words: Yup.array().min(1).required(),
});

const updateValidation = Yup.object().shape({
  words: Yup.array().min(1).required(),
});

const measureInitialValues = {
  part_collection_id: undefined,
  width: 0,
  depth: 0,
  height: 0,
  code: '',
};

const setMeasureInitialValues = (data) => ({
  width: data?.width || 0,
  depth: data?.depth || 0,
  height: data?.height || 0,
});

const storeMeasureValidation = Yup.object().shape({
  part_collection_id: Yup.number().required(),
  width: Yup.number().min(0).required(),
  depth: Yup.number().min(0).required(),
  height: Yup.number().min(0).required(),
  code: Yup.string().length(2).required(),
});

const updateMeasureValidation = Yup.object().shape({
  width: Yup.number().min(0).required(),
  depth: Yup.number().min(0).required(),
  height: Yup.number().min(0).required(),
});

const formConfig = {
  // codes and categories
  initialValues,
  setInitialValues,
  storeValidation,
  updateValidation,
  // measures
  measureInitialValues,
  setMeasureInitialValues,
  storeMeasureValidation,
  updateMeasureValidation,
};

export default formConfig;
