import PartTypeEnum from '../../../../constants/enums/PartTypeEnum';
import { useRequest } from 'ahooks';
import partsService from '../../../../services/parts/partsService';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TranslatableDisplay from '../../../../components/UI/Displays/TranslatableDisplay';
import { AppContext } from '../../../../components/AppContext';
import { get } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import CreateButton from '../../../../components/UI/Buttons/CreateButton';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';

export default function AssemblyRelatedCreation({ part }) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentLocale } = useContext(AppContext)

  const { data = [], run: getAssemblies  } = useRequest(partsService.getAssemblyRelatedParts, {
    ready: part && part.part_type === PartTypeEnum.values.ASSEMBLY,
    defaultParams: [part.uuid]
  });

  const { runAsync: StoreAssemblyFromAssembly, loading } = useRequest(partsService.storeAssemblyFromAssembly, {
    manual: true
  })

  useEffect(() => {
    getAssemblies(part?.uuid)
  }, [part]);

  const handleCreate = (related) => {
    SweetAlert.fire({
      text: `Sei sicuro di voler creare ${related.code}?`,
      titleText: t('are_you_sure'),
      icon: 'warning',
      showCancelButton: true,
    }).then((response) => {
        if (response.isConfirmed) {
          StoreAssemblyFromAssembly(part.uuid, {
            'assy_state': related.code.slice(-2)
          })
          .then(res => getAssemblies(part.uuid))
          .catch(err => toast.error(err?.response?.data?.message))
        }
      }
    )
  }

  if (PartTypeEnum.values.ASSEMBLY === part.part_type) {
    return (
      <div className='card w-100'>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center gap-4'>
            <span className='text-black-50'>{t('parts.related')}</span>
          </div>
          <ul className='list-group list-group-flush'>
            {data.map((related, index) => (
              <li className="list-group-item px-1 d-flex align-items-center justify-content-between" key={`related_${index}`}>
                {related.uuid
                  ? <div>
                    <Link to={`/parts/${related.uuid}`}>{related.code}</Link>
                    <TranslatableDisplay
                      currentTranslation={get(related.description, currentLocale, '')}
                      translatableArray={related.description}
                    />
                  </div>
                  : <>
                    <span className='fw-bold'>{related.code}</span>
                    <CreateButton disabled={loading} type='button' onClick={() => handleCreate(related)}/>
                  </>
                }

              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }

  return (<></>)

}
